import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import { download_quiz, get_exam_logs } from 'API/quiz'
import axios from 'axios'
import { QuizRenderer } from 'components/quiz/Renderer'
import { Link, PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import 'styles/index.scss'
import Swal from 'sweetalert2'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { QuizStateActions } from 'redux/slices/QuizState'
import { QuizSubmissionStateActions } from 'redux/slices/QuizSubmissionState'
import { UserType } from 'types/UserAdditionMetaData'
import { NonUndefined, PromiseType } from 'utility-types'
import { getQuiz, getQuizAsTeacher } from '../../../../../API/getQuiz'
import { ListSubmissions } from '../assignment/ListSubmissions'
// import { red } from '@material-ui/core/colors'
// import { DisplayImageComponent } from 'components/quiz/DisplayImageComponent 2'
import { exportToCsv } from 'pages/admin/course/[id]'

const Image = '/quizFinish.svg'

const QuizView: React.FC<PageProps> = ({ params: { id, quiz_id } }) => {
	const { ShowDetails, viewAs } = useGetCourseDetails(id)
	const [marksScored, setMarksScored] = useState(0)
	const [asset, setAsset] = React.useState<PromiseType<ReturnType<typeof getQuiz>>>()
	const dispatch = useAppDispatch()
	const [, setUpdate] = useState(0)

	const quizData = useAppSelector((state) => state.QuizReducer.attemptData)

	React.useEffect(() => {
		const run = async () => {
			const data = await getQuiz({ quiz_id, id, viewAs })

			if (data) {
				setAsset(data)
				console.log(data)
				const marks = data?.answers?.reduce((a: number, b: number) => a + b?.marks, 0)
				setMarksScored(marks)
				dispatch(
					QuizStateActions.setState({
						questionData: {
							...data,
						},
						questions: [],
					})
				)
			}
		}

		run()
	}, [id, quiz_id, viewAs])

	React.useEffect(() => {
		const interval = setInterval(() => {
			console.log('interval', interval)
			if (quizData.attempting) {
				setUpdate((update) => update + 1)
				// console.log('quizData', quizData)
			}
		}, 1000)
		return () => {
			clearInterval(interval)
		}
	}, [quizData.attempting])

	return (
		<>
			{ShowDetails}
			{asset && viewAs !== 'Teacher' && (
				<>
					{new Date(asset.available_from) > new Date() && !asset.proctor && <>Quiz Not Started.</>}
					{asset.finished && !asset.proctor && 'Quiz Submitted.'}
					<br />
					<b>
						{asset.finished &&
							!asset.proctor &&
							new Date(asset.releaseMarksDate) < new Date() &&
							` Marks Scored = ${marksScored} / ${asset.marks}`}
					</b>

					{asset.finished && asset.proctor && 'Exam Submitted.'}

					{new Date(asset.available_till) < new Date() && !asset.proctor && (
						<>
							{!asset.finished && 'Quiz Missed.'}
							<div className='email-container'>
								<div className='row px-md-2 justify-content-center row-emailContent'>
									<div className='col-md-6 col-emailContent'>
										<h3>Quiz Ended!</h3>
										<p className='mb-2'>The quiz has ended.</p>
										<Link to='/' className='btn btn-primary no-hover no-shadow'>
											<i className='bx bx-chevron-left'></i> Go Back
										</Link>
									</div>
									<div className='col-md-6 order-first order-md-last emailImg'>
										<div className='text-center'>
											<img src={Image} alt='' />
										</div>
									</div>
								</div>
							</div>
						</>
					)}

					{new Date(asset.available_till) < new Date() && asset.proctor && (
						<>
							{!asset.finished && 'Exam Missed.'}
							<div className='email-container'>
								<div className='row px-md-2 justify-content-center row-emailContent'>
									<div className='col-md-6 col-emailContent'>
										<h3>Exam Ended!</h3>
										<p className='mb-2'>The exam has ended.</p>
										<Link to='/' className='btn btn-primary no-hover no-shadow'>
											<i className='bx bx-chevron-left'></i> Go Back
										</Link>
									</div>
									<div className='col-md-6 order-first order-md-last emailImg'>
										<div className='text-center'>
											<img src={Image} alt='' />
										</div>
									</div>
								</div>
							</div>
						</>
					)}
					{new Date(asset.available_till) > new Date() && !asset.proctor && (
						<QuizRenderer viewAs={viewAs} course={id} renderAs='attempting' exam_ended={asset.finished}>
							{new Date(asset.available_from) < new Date() && new Date(asset.available_till) > new Date() && !asset.finished && (
								<>
									{asset.questions.length === 0 && !asset.proctor ? (
										<>
											<div className='button-in'>
												<a
													href={`/quiz/course/${id}/${quiz_id}`}
													target='_blank'
													rel='noopener noreferrer'
													className='btn btn-primary btn-lg button-startquiz'
												>
													Start Quiz
												</a>
											</div>
										</>
									) : (
										<>
											{!asset.finished && !asset.proctor && (
												<>
													<a
														href={`/quiz/course/${id}/${quiz_id}`}
														target='_blank'
														rel='noopener noreferrer'
														className='btn btn-primary'
													>
														View Quiz
													</a>
												</>
											)}
										</>
									)}
								</>
							)}
						</QuizRenderer>
					)}
					{/* {asset.finished &&
						!asset.proctor &&
						asset.allowMultiple &&
						new Date(asset.available_from) < new Date() &&
						new Date(asset.available_till) > new Date() && (
							<div
								className='btn btn-primary btn-lg button-startquiz'
								onClick={() => {
									Swal.fire({
										title: 'Deleting previous Attempt Quiz means : All your previous result related to this quiz will be deleted',
										confirmButtonText: 'Ok',
										showCancelButton: true,
									}).then(async (result: any) => {
										if (result.value) {
											const response = await axios.delete(
												getUrl(`quiz/delete_previous_response?quiz_id=${quiz_id}&course_id=${id}`),
												getDefaultOptions()
											)
											window?.location?.reload()
										} else {
										}
									})
								}}
							>
								Delete Previous Attempt
							</div>
						)} */}
					{asset.finished &&
						!asset.proctor &&
						asset.allowMultiple &&
						new Date(asset.available_from) < new Date() &&
						new Date(asset.available_till) > new Date() && (
							<div
								className='btn btn-primary btn-lg button-startquiz'
								onClick={() => {
									Swal.fire({
										title: 'Re-Attempt Quiz means : All your previous result regarding this quiz will be disregarded and your latest response will be considered',
										confirmButtonText: 'Ok',
										showCancelButton: true,
									}).then(async (result: any) => {
										if (result.value) {
											const response = await handleApi(
												axios.post(
													getUrl(`quiz/update_attempt_status`),
													{
														quiz_id: quiz_id,
														course_id: id,
													},
													getDefaultOptions()
												)
											)
											if (response) {
												window?.location?.reload()
											}
										} else {
										}
									})
								}}
							>
								Re-Attempt Quiz
							</div>
						)}
					{asset.proctor && new Date(asset.available_till) > new Date() && (
						<QuizRenderer viewAs={viewAs} course={id} renderAs='attempting' exam_ended={asset.finished}>
							{!asset.finished && (
								<>
									{asset.questions.length === 0 && asset.proctor ? (
										<>
											<div className='button-in'>
												<a
													href={`/quiz/course/${id}/${quiz_id}`}
													target='_blank'
													rel='noopener noreferrer'
													className='btn btn-primary button-startquiz'
												>
													Go To Exam Page
												</a>
											</div>
										</>
									) : (
										<>
											{!asset.finished && asset.proctor && (
												<>
													<a
														href={`/quiz/course/${id}/${quiz_id}`}
														target='_blank'
														rel='noopener noreferrer'
														className='btn btn-primary'
													>
														View Exam
													</a>
												</>
											)}
										</>
									)}
								</>
							)}
						</QuizRenderer>
					)}
				</>
			)}
			{asset && viewAs === 'Teacher' && (
				<>
					<ShowQuizSubmissionForStudent quiz_id={quiz_id} course_id={id} asset={asset} />
				</>
			)}
		</>
	)
}

export const getQuizSubmissionList = async (quiz_id: string) =>
	await handleApi<(UserType & { submitted: boolean; marks: number; givenMarks: number })[]>(
		axios.get(getUrl('quiz/getSubmissionList?quiz_id=' + quiz_id), getDefaultOptions())
	)

function ShowQuizSubmissionForStudent({ quiz_id, course_id, asset }: { quiz_id: string; course_id: string; asset: any }) {
	const [users, setUsers] = React.useState<NonUndefined<PromiseType<NonUndefined<ReturnType<typeof getQuizSubmissionList>>>>>()
	const [localUpdate, setLocalUpdate] = React.useState(false)
	const dispatch = useAppDispatch()
	const update = useAppSelector((state) => state.ApplicationReducer.update)
	const [LiveLogs, setLiveLogs] = useState<any>([])
	React.useEffect(() => {
		const run = async () => {
			// if (!users) {
			const submissionList = await getQuizSubmissionList(quiz_id)
			console.log({ submissionList })
			if (submissionList) {
				setUsers(submissionList)
			}
			// }
		}
		dispatch(QuizSubmissionStateActions.setSelectedQuizId(quiz_id))
		run()
	}, [update])
	const user_id_selected = useAppSelector((state) => state.QuizSubmissionReducer.user_id)

	React.useEffect(() => {
		const run = async () => {
			// getLiveLogs()
			// getQuiz()
			if (user_id_selected) {
				const q = await getQuizAsTeacher({
					id: course_id,
					quiz_id: quiz_id,
					student_id: user_id_selected,
				})
				console.log({ q })
				dispatch(
					QuizStateActions.setState({
						questions: q ?? [],
					})
				)
				setLocalUpdate(false)
				setLocalUpdate(true)
			}
			// ()/
		}
		run()
		console.log({ user_id_selected, quiz_id })
	}, [user_id_selected])

	// const getLiveLogs = async () => {
	// 	console.log('LOG')
	// 	if (asset.proctor) {
	// 		const data = await get_exam_logs({ quiz_id })
	// 		if (data) setLiveLogs(data)
	// 	}

	// 	setTimeout(getLiveLogs, 2500)
	// }

	return (
		<>
			<div
				onClick={async () => {
					console.log({ quiz_id, course_id })
					const data = await download_quiz({ quiz_id, course_id })
					console.log(data)
					if (window !== undefined && data) {
						const url = window.URL.createObjectURL(new Blob([data as any]))
						const link = document.createElement('a')
						link.href = url
						link.setAttribute('download', quiz_id + '_quiz.xlsx' ?? '') //or any other extension
						document.body.appendChild(link)
						link.click()
					}
				}}
				className='btn btn-primary'
			>
				Download Question Bank
			</div>
			{asset.proctor && (
				<>
					<div
						onClick={async () => {
							console.log({ quiz_id, course_id })
							const data: any = await get_exam_logs({ quiz_id })
							if (data) {
								console.log(data)
								const headers = [
									'first_name',
									'last_name',
									'email',
									'username',
									'action_done',
									'action_time',
									'student_photo',
									'report_action',
									'report_action_reason',
								]
								const headers2 = [
									'First Name',
									'Last Name',
									'Email',
									'Username',
									'Action',
									'Action Time',
									'Student Photo',
									'Report Action',
									'Report Reason',
								]

								const csv_value = data.map((elem: any) => {
									const row: any = []
									headers.forEach((header) => {
										if (header === 'action_time') {
											row.push(new Date((elem as any)?.[header]).toLocaleString() ?? undefined)
										} else if (header === 'student_photo') {
											row.push(`${getUrl(`static/${elem?.photo_file}?token=${elem.photo_token}`)}`)?.[header] ?? undefined
										} else {
											row.push((elem as any)?.[header] ?? undefined)
										}
									})
									return row
								})
								const arr = [headers2, ...csv_value]
								console.log(arr)
								exportToCsv(`Exam_Logs_${new Date().toLocaleDateString()}`, arr)
							}
						}}
						className='btn btn-primary'
					>
						Download Logs
					</div>
					{/* //logs */}
					{/* <div className='btn btn-primary'>View Logs</div> */}
					<Link to={`/course/${course_id}/activities/quiz/${quiz_id}/livelogs`} className='btn btn-primary no-hover no-shadow'>
						<div>Go to Exam Dashboard</div>
					</Link>
					{/* http://localhost:8000/course/Data-Automation-1-6/activities/quiz/livelogs/eqeeq-857/livelogs */}
				</>
			)}

			{/* {asset.proctor && (
				<div className='col-6'>
					<span>
						<h4>LIVE LOGS</h4>
					</span>
					<table className='table'>
						<thead>
							<tr>
								<th scope='col'>Index</th>
								<th scope='col'>Name</th>
								<th scope='col'>Username</th>
								<th scope='col'>Action Done</th>
								<th scope='col'>Action Time</th>
								<th scope='col'>Student Photo</th>
							</tr>
						</thead>
						{LiveLogs.map((elem: any, index: number) => {
							return (
								<>
									<tr>
										<th scope='row'>{index + 1}</th>
										<td>
											{elem.student.first_name} {elem.student.last_name}
										</td>
										<td>{elem.student.username}</td>
										{elem.action_done != 'EXAM STARTED' &&
											elem.action_done != 'EXAM ENDED' &&
											elem.action_done !== '5 SECOND TIMER' && <td style={{ color: 'red' }}>{elem.action_done}</td>}
										{(elem.action_done == 'EXAM STARTED' ||
											elem.action_done == 'EXAM ENDED' ||
											elem.action_done === '5 SECOND TIMER') && <td style={{ color: 'blue' }}>{elem.action_done}</td>}
										
										<td>{new Date(elem.action_time).toLocaleString()}</td>
										<td>
											{elem.photo && (
												<img
													height={300}
													src={`${
														elem?.photo.photo_file
															? getUrl(`static/${elem?.photo.photo_file}?token=${elem.photo.photo_token}`)
															: ''
													}`}
													alt=''
												/>
											)}
										</td>
									</tr>
								</>
							)
						})}
					</table>
				</div>
			)} */}

			<div className='mt-4 mb-4 px-0 classworkDetailsContainer container'>
				<div className='app-content content overflow-hidden'>
					<div className='content-overlay'></div>
					<div className='header-navbar-shadow'></div>
					<div className='content-wrapper animate__animated animate__fadeIn'>
						<div className='app-calendar overflow-hidden border'>
							<div className='no-gutters row'>
								<div
									id='app-calendar-sidebar'
									className={`col app-calendar-sidebar grading ${
										true
											? // isListVisible
											  'show'
											: ''
									}  flex-grow-0 overflow-hidden d-flex flex-column col`}
								>
									<div className='sidebar-wrapper scrollStudentList'>
										<ListSubmissions type={'quiz'} users={users ?? []} user_id={user_id_selected}></ListSubmissions>
									</div>
								</div>

								<div className='position-relative col'>
									<div className='shadow-none border-0 mb-0 rounded-0 card'>
										<div className='pb-0 card-body  px-0'>
											{false ? (
												<></>
											) : (
												<>
													{localUpdate && (
														<QuizRenderer viewAs={'Teacher'} course={course_id} renderAs='grading'></QuizRenderer>
													)}
												</>
											)}
										</div>
									</div>
								</div>
								<div className={`body-content-overlay ${false ? 'show' : ''}`}></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default QuizView
